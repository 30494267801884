<template>
  <div class="w-full">
    <modal
      class-name="flex flex-col rounded px-6 md:px-12 w-full md:w-443px lg:w-443px"
      ref="accountModal"
    >
      <h4
        class="text-xl xl:text-20 font-bold mb-16 text-left text-gray-700"
      >
        {{ transferForm.data.stage.value === 'confirm' ? 'Confirm Transfer' : 'Bank Transfer'  }}
      </h4>
      <div
        class="h-16 bg-ash-600 -mx-6 -mt-12 md:-mx-12 xl:-mx-12"
      >
        <div class="inline-flex px-6 mb-3 lg:px-12 pt-4">
          <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill="#274FED" />
            <path d="M9.00578 13C8.72832 13 8.4894 12.9077 8.28902 12.723C8.09634 12.5309 8 12.3019 8 12.036C8 11.7701 8.09634 11.5448 8.28902 11.3601C8.4894 11.1754 8.72832 11.0831 9.00578 11.0831C9.28324 11.0831 9.5183 11.1754 9.71098 11.3601C9.90366 11.5448 10 11.7701 10 12.036C10 12.3019 9.90366 12.5309 9.71098 12.723C9.5183 12.9077 9.28324 13 9.00578 13ZM8.21965 7.28255V5H9.78035V7.28255L9.6185 10.2078H8.3815L8.21965 7.28255Z" fill="white" />
          </svg>
          <p class="text-10 px-2 text-ash-700">
            Transfers can only be made to the bank account number registered to your account.
          </p>
        </div>
      </div>

      <div class="mt-12">
        <h4
          class="text-10 font-semibold mb-4 text-left"
          style="color: #222222;"
        >
          Transfer Amount
        </h4>
        <div>
          <div>
            <template v-if="transferForm.data.stage.value === 'transfer'">
              <form-group
                type="money"
                name="amount"
                :form="transferForm"
                v-model="transferForm.data.amount.value"
                :autofocus="true"
              />

              <div class="flex flex-row -mt-3">
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle r="5.5" transform="matrix(1 0 0 -1 5.5 5.5)" fill="#6D82D6" />
                  <path d="M4.96631 8.44043L5.05615 4.66309H5.9585L6.04834 8.44043H4.96631ZM4.86084 3.31934C4.86084 2.94434 5.11475 2.69824 5.50928 2.69824C5.90381 2.69824 6.15771 2.94434 6.15771 3.31934C6.15771 3.69824 5.90381 3.94434 5.50928 3.94434C5.11475 3.94434 4.86084 3.69824 4.86084 3.31934Z" fill="white" />
                </svg>
                <p class="text-10 ml-2 text-purple-200 font-semibold">
                  Available credit • ₦ {{ accountBalance | currency }}
                </p>
              </div>
            </template>

            <template v-if="transferForm.data.stage.value === 'confirm'">
              <div class="flex flex-row">
                <svg width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="35" height="24" rx="3" fill="#14D08E"/>
                  <path d="M15.5638 10.4999C15.5638 9.50595 16.3697 8.7 17.3638 8.7C18.3578 8.7 19.1638 9.50595 19.1638 10.4999C19.1638 11.4939 18.3578 12.2999 17.3638 12.2999C16.3697 12.2999 15.5638 11.4939 15.5638 10.4999Z" stroke="white" stroke-width="0.9"/>
                  <path d="M23.5759 6H11.4241C10.6388 6 10 6.64479 10 7.43752V13.1874C10 13.9799 10.6388 14.6247 11.4241 14.6247H23.5759C24.3612 14.6247 25 13.9799 25 13.1872V7.43752C25 6.64479 24.3612 6 23.5759 6ZM24.0507 13.1872C24.0507 13.4516 23.8379 13.6665 23.5759 13.6665H11.4241C11.1623 13.6665 10.9493 13.4516 10.9493 13.1872V7.43752C10.9493 7.17334 11.1623 6.95828 11.4241 6.95828H23.5759C23.8377 6.95828 24.0507 7.17315 24.0507 7.43752V13.1872Z" fill="white"/>
                  <path d="M13.6562 7.6875H12.1562C11.8973 7.6875 11.6875 7.89731 11.6875 8.15624C11.6875 8.41516 11.8973 8.62497 12.1562 8.62497H13.6562C13.9152 8.62497 14.125 8.41516 14.125 8.15624C14.125 7.89749 13.9152 7.6875 13.6562 7.6875Z" fill="white"/>
                  <path d="M22.4687 12H20.9687C20.7098 12 20.5 12.2098 20.5 12.4687C20.5 12.7277 20.7098 12.9375 20.9687 12.9375H22.4687C22.7277 12.9375 22.9375 12.7277 22.9375 12.4687C22.9375 12.2098 22.7277 12 22.4687 12Z" fill="white"/>
                  <path d="M23.4062 15.375H11.5938C11.3348 15.375 11.125 15.5848 11.125 15.8437C11.125 16.1027 11.3348 16.3125 11.5938 16.3125H23.4062C23.6652 16.3125 23.875 16.1027 23.875 15.8437C23.875 15.5848 23.6652 15.375 23.4062 15.375Z" fill="white"/>
                  <path d="M22.6769 17.0625H12.3231C12.0756 17.0625 11.875 17.2631 11.875 17.5106V17.552C11.875 17.7995 12.0756 18.0002 12.3231 18.0002H22.6769C22.9244 18.0002 23.125 17.7995 23.125 17.552V17.5106C23.125 17.2631 22.9244 17.0625 22.6769 17.0625Z" fill="white"/>
                </svg>
                <p class="text-18 ml-3 text-gray-700 font-semibold">
                  {{ transferForm.data.amount.value | currency }}
                </p>
              </div>
            </template>
          </div>
        </div>
      </div>
 
      <div class="mt-16">
        <h4 class="text-10 lg:text-12 font-bold mb-4 text-left text-blue-900">
          To your bank account
        </h4>
        <div>
          <div class="flex">
            <svg width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="35" height="24" rx="3" fill="#242D4F" />
              <path d="M23.8143 10.8064L17.7795 6.09634C17.5938 5.96789 17.3617 5.96789 17.176 6.09634L11.1411 10.8064C11.0019 10.9349 10.9555 11.149 11.0483 11.2774C11.0947 11.4487 11.2804 11.5772 11.4661 11.5772H12.8588V15.8591H11.9303C11.6518 15.8591 11.4661 16.0303 11.4661 16.2872V17.5718C11.4661 17.8287 11.6518 18 11.9303 18H23.0716C23.3501 18 23.5358 17.8287 23.5358 17.5718V16.2872C23.5358 16.0303 23.3501 15.8591 23.0716 15.8591H22.1431V11.5772H23.5358C23.7215 11.5772 23.9072 11.4487 23.9536 11.2774C24.0464 11.149 24 10.9349 23.8143 10.8064ZM13.7872 11.5772H15.6441V15.8591H13.7872V11.5772ZM18.4294 15.8591H16.5725V11.5772H18.4294V15.8591ZM22.6073 17.1436H12.3945V16.7154H22.6073V17.1436ZM21.2147 15.8591H19.3578V11.5772H21.2147V15.8591ZM12.7195 10.7208L17.5009 6.99554L22.2824 10.7208H12.7195Z" fill="white" />
            </svg>
            <p class="text-12 mt-1 text-xs lg:text-base text-gray-700 ml-4 mr-5 text-ash-700">
              <span v-if="accountNumber">{{ accountNumber }}</span>
              <span class="opacity-50 text-sm" v-else>Account number not verified</span>
            </p>
            <p class="text-12 mt-1 text-xs lg:text-base text-gray-700 text-ash-700">{{ bankName }}</p>
          </div>
        </div>
      </div>

      <template v-if="getFormError(transferForm) || !accountNumber || !bankName">
        <div class="alert alert-red-soft mt-10">
          <div class="alert-icon mr-3">
            !
          </div>
          <div class="text-xs font-normal">
            <span v-if="!accountNumber || !bankName">Your bank account information hasn't been verified</span>
            <span v-else>{{ getFormError(transferForm) }}</span>
          </div>
        </div>
      </template>

      <div class="flex flex-wrap gap-3 justify-center mt-12">
        <template v-if="transferForm.data.stage.value === 'transfer'">
          <button
            type="button"
            class="btn btn-blue btn-md"
            :disabled="!accountNumber || !bankName"
            @click.prevent="confirmTransfer"
          >
            Make Transfer
          </button>
        </template>
        <template v-if="transferForm.data.stage.value === 'confirm'">
          <button
            type="button"
            class="btn btn-gray-soft btn-md"
            @click.prevent="transferForm.data.stage.value = 'transfer'"
          >
            <span class="text-ash-800">Edit</span>
          </button>
          <button
            type="button"
            class="btn btn-blue btn-md"
            :disabled="transferForm.loading || !accountNumber || !bankName"
            @click.prevent="makeTransfer"
          >
            <sm-loader-white v-if="transferForm.loading" />
            <span v-else>Confirm Transfer</span>
          </button>
        </template>
      </div>
    </modal>

  </div>
</template>

<script>
export default {
  props: ['userType'],
  data() {
    return {
      transferForm: this.$options.basicForm([
        { name: 'stage', value: 'transfer' },
        { name: 'amount', rules: 'required|number|min:50' },
      ]),
    }
  },
  computed: {
    accountBalance() {
      return this.creditCard?.available_credit;
    },
    accountName() {
      return `${this.user?.name} ${this.user?.last_name}`;
    },
    accountNumber() {
      return this.user?.profile?.account_no;
    },
    creditCard() {
      return this.personalCards.data?.[0];
    },
    bankName() {
      return this.$options.filters.bankName(this.user?.profile?.bank_name, this.$store.state.banks)
    },
    personalCards() {
      return this.resources.personalCards;
    },
  },
  methods: {
    close() {
      this.$emit('close');
      this.$refs.accountModal.close();
    },
    confirmTransfer() {
      if (!this.validateUser('onboarding')) {
        return this.resumeOnboarding();
      }

      if (!this.validateForm(this.transferForm)) {
        return false;
      }

      if ((this.creditCard?.available_credit - this.transferForm.data.amount.value) < 100) {
        const minimumRequiredToTransfer = this.transferForm.data.amount.value + 100;
        const formattedAmount = this.$options.filters.currency(minimumRequiredToTransfer);

        this.close();
        this.$error({
          title: 'Transfer Failed',
          body: `There must be a minimum balance of ₦${formattedAmount} in your account`,
          button: 'Okay',
        });
        return false;
      }

      this.transferForm.data.stage.value = 'confirm'
    },
    open() {
      this.$refs.accountModal.open();
    },
    async makeTransfer() {
      if (!this.validateForm(this.transferForm)) {
        return false;
      }
      this.transferForm.error = null;
      this.transferForm.setLoading();
      await this.$post({
        url: `${this.$baseurl}/transfer/initiate`,
        headers: this.headers,
        data: {
          amount: this.transferForm.data.amount.value,
          user_type: this.userType,
          account_id: this.user?.personal_account?.id
        },
        success: () => {
          this.close();
          this.$success({
            title: 'Transfer Successful',
            button: 'Yay!',
          });
          this.$emit('getPersonalCards')
          this.transferForm = this.resetForm(this.transferForm);
        },
        error: (error) => {
          this.transferForm.error = error;
          this.mapFormErrors(error.response ?.data ?.errors);
        }
      });
      this.transferForm.setLoading(false);
    }
  },
}
</script>